import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { FaExternalLinkAlt } from 'react-icons/fa';
import SEO from '../components/seo';
import Layout from '../components/layout';
import EOCardGraphic from '../images/diversity/equal-opportunity.png';
import SHARPCardGraphic from '../images/diversity/sharp-logo.png';
import LifeConnectionCardGraphic from '../images/diversity/life-connection-logo-final.png';
import DiversityLeadImg from '../images/diversity/wheatley-web.jpg';
import SharpLeadImg from '../images/diversity/warren-web.jpg';
import LifeConnectionLeadImg from '../images/diversity/photo-not-available.jpg';
import EqualOpportunityLeadImg from '../images/diversity/msg-smith-web.png';
import DoDSafeHelpline from '../images/diversity/DoD Safe Helpline.jpeg';
import DomesticViolenceHotline from '../images/diversity/Domestic Violence Hotline.jpeg';
import MilitaryCrisisLine from '../images/diversity/Military Crisis Line.jpeg';
import SuicidePreventionLifeline from '../images/diversity/Suicide Prevention Lifeline.jpeg';

const PageHeader = styled.header`
  background-color: black;
  color: rgb(249, 200, 3);
  text-align: center;
  font-size: 0.5rem;
  padding: 1em 0;
  @media screen and (min-width: 35em) {
    padding: 2em 0;
    font-size: initial;
  }
`;

const PurposeScope = styled.span`
  font-weight: bold;
  color: darkred;
`;

const Mission = () => (
  <section
    css={css`
      font-size: larger;
    `}
  >
    <p>
      <PurposeScope>The Center’s Foundational Mission</PurposeScope>
    </p>
    <p>
      The MEDCoE DEI Center serves as the principal special staff proponent created to
      help conceptualize MEDCoE’s People First Strategy; helping equip leaders and
      connecting Soldiers, Civilians, and family members with vital resources to more
      effectively combat corrosive behavioral trends that are detrimental to
      organizational readiness, resilience, and morale/welfare.
    </p>
  </section>
);

const Hotlines = () => (
  <section>
    <div className='grid-row flex-row flex-align-center'>
      <div className='tablet:grid-col'>
        <a href='https://safehelpline.org' target='_blank'>
          <img src={DoDSafeHelpline} alt='DoD Safe Helpline' />
        </a>
      </div>
      <div className='tablet:grid-col'>
        <a href='https://www.thehotline.org/' target='_blank'>
          <img src={DomesticViolenceHotline} alt='Domestic Violence Hotline' />
        </a>
      </div>
      <div className='tablet:grid-col'>
        <a
          href='https://www.veteranscrisisline.net/get-help/military-crisis-line'
          target='_blank'
        >
          <img src={MilitaryCrisisLine} alt='Military Crisis Line' />
        </a>
      </div>
      <div className='tablet:grid-col'>
        <a href='https://suicidepreventionlifeline.org/' target='_blank'>
          <img src={SuicidePreventionLifeline} alt='Suicide Prevention Lifeline' />
        </a>
      </div>
    </div>
  </section>
);

const Leadership = () => (
  <section>
    <h2>Leadership</h2>
    <div className='grid-row'>
      <div className='tablet:grid-col'>
        <img
          src={DiversityLeadImg}
          alt='CPT Benjamin M. Wheatley'
          css={css`
            border-radius: 50%;
            max-height: 14em;
            min-height: 14em;
          `}
        />
        <h4 className='leader-name'>CPT Benjamin M. Wheatley</h4>
        <h4 className='leader-title margin-top-neg-2 text-italic text-normal'>
          Director, MEDCoE Diversity, Equity, and Inclusion (DEI) Center
        </h4>
      </div>
      <div className='tablet:grid-col'>
        <img
          src={SharpLeadImg}
          alt='Mr. Curtis L. Warren, Jr.'
          css={css`
            border-radius: 50%;
            max-height: 14em;
            min-height: 14em;
          `}
        />
        <h4 className='leader-name'>Mr. Curtis L. Warren, Jr.</h4>
        <h4 className='leader-title margin-top-neg-2 text-italic text-normal'>
          MEDCoE SHARP Program Manager
        </h4>
      </div>
      <div className='tablet:grid-col'>
        <img
          src={LifeConnectionLeadImg}
          alt='CH (LTC) Michael W. Spikes'
          css={css`
            border-radius: 50%;
            max-height: 14em;
            min-height: 14em;
          `}
        />
        <h4 className='leader-name'>CH (LTC) Michael W. Spikes</h4>
        <h4 className='leader-title margin-top-neg-2 text-italic text-normal'>
          MEDCoE Command Chaplain
        </h4>
      </div>
      <div className='tablet:grid-col'>
        <img
          src={EqualOpportunityLeadImg}
          alt='MSG Jachen A. Smith'
          css={css`
            border-radius: 50%;
            max-height: 14em;
            min-height: 14em;
          `}
        />
        <h4 className='leader-name'>MSG Jachen A. Smith</h4>
        <h4 className='leader-title margin-top-neg-2 text-italic text-normal'>
          MEDCoE Equal Opportunity Advisor (EOA)
        </h4>
      </div>
    </div>
  </section>
);

const CardGrid = () => {
  return (
    <ul className='usa-card-group'>
      <li className='tablet:grid-col-4 usa-card usa-card--header-first'>
        <div className='usa-card__container'>
          <header className='usa-card__header'>
            <h2 className='usa-card__heading'>Equal Opportunity</h2>
          </header>
          <div className='usa-card__media'>
            <div className='usa-card__img'>
              <img src={EOCardGraphic} alt='Equal Opportunity Graphic' />
            </div>
          </div>
          <div className='usa-card__body'>
            <p>
              The Equal Opportunity (EO) Program formulates, directs, and sustains a
              comprehensive effort to maximize human potential to ensure fair treatment
              for military personnel, family members, and DA Civilians without regard to
              race, color, sex (to include gender identity), religion, national origin, or
              sexual orientation, and provide an environment free of unlawful
              discrimination and offensive behavior.
            </p>
          </div>
        </div>
      </li>
      <li className='tablet:grid-col-4 usa-card usa-card--header-first'>
        <div className='usa-card__container'>
          <header className='usa-card__header'>
            <h2 className='usa-card__heading'>SHARP</h2>
          </header>
          <div className='usa-card__media'>
            <div className='usa-card__img padding-x-3 bg-white'>
              <img src={SHARPCardGraphic} alt='SHARP Graphic' />
            </div>
          </div>
          <div className='usa-card__body'>
            <p>
              The SHARP Program's mission is to reduce with an aim toward eliminating
              sexual assault and sexual harassment within the Army through cultural
              change, prevention, intervention, investigation, accountability,
              advocacy/response, assessment, and training to sustain the All-Volunteer
              Force.
            </p>
          </div>
          <div className='usa-card__footer'>
            {/* TODO: add links from APT  */}
            <Link to='/sharp' className='usa-button'>
              Learn More
            </Link>
          </div>
        </div>
      </li>
      <li className='tablet:grid-col-4 usa-card usa-card--header-first'>
        <div className='usa-card__container'>
          <header className='usa-card__header'>
            <h2 className='usa-card__heading'>Life Connection</h2>
          </header>
          <div className='usa-card__media'>
            <div className='usa-card__img padding-x-9 bg-white'>
              <img src={LifeConnectionCardGraphic} alt='Life Connection Graphic' />
            </div>
          </div>
          <div className='usa-card__body'>
            <p>
              Fostering a culture of readiness, resiliency, and trust through building
              connections and focusing on personal and relational health aimed at suicide
              prevention and self-care.
            </p>
          </div>
          <div className='usa-card__footer'>
            {/* TODO: add links from APT  */}
            <Link to='/life-connection' className='usa-button'>
              Learn More
            </Link>
          </div>
        </div>
      </li>
      <li className='tablet:grid-col-4 usa-card usa-card--header-first'>
        <div className='usa-card__container'>
          <header className='usa-card__header'>
            <h2 className='usa-card__heading'>Diversity Videos</h2>
          </header>
          <div className='usa-card__media'>
            <div className='usa-card__img'>
              <img
                src='https://cdn.dvidshub.net/media/frames/video/2105/794810.jpg'
                alt='DVIDS video still'
              />
            </div>
          </div>
          <div className='usa-card__body'>
            <p>
              #ServeTogether is an enduring diversity outreach video series documenting
              testimonies of Soldiers, Family Members and DoD Civilians throughout MEDCoE
              that aims to highlight the impactful stories of our uniquely diverse
              population.
            </p>
          </div>
          <div className='usa-card__footer'>
            <Link to='https://www.dvidshub.net/search/?q=servetogether&view=grid' className='usa-button'>
              More videos
            </Link>
          </div>
        </div>
      </li>
      <li className='tablet:grid-col-4 usa-card usa-card--header-first'>
        <div className='usa-card__container'>
          <header className='usa-card__header'>
            <h2 className='usa-card__heading'>Events / Educational Material</h2>
          </header>
          <div className='usa-card__body'>
            <p>
              For those with CAC access, click here to navigate to the training/resource
              library and event calendar.
            </p>
          </div>
          <div className='usa-card__footer'>
            <a
              href='https://intranet.tradoc.army.mil/sites/medcoe/home/ss/dei/SitePages/Home.aspx'
              className='usa-button'
              target='_blank'
            >
              SharePoint
            </a>
          </div>
        </div>
      </li>
      <li className='tablet:grid-col-4 usa-card  usa-card--header-first'>
        <div className='usa-card__container'>
          <header className='usa-card__header'>
            <h2 className='usa-card__heading'>Contact Us</h2>
          </header>
          <div className='usa-card__body'>
            <address>
              MEDCoE Diversity, Equity, &amp; Inclusion (DEI) Center
              <br />
              3250 Koehler Rd., BLDG 1350, Room 0110
              <br />
              Fort Sam Houston, TX 78234-5093
            </address>
            <p>210-221-5254</p>
            <p className='margin-top-neg-2'>
              <a
                className='usa-link usa-link--external'
                target='_blank'
                rel='noopener noreferrer'
                href='mailto:usarmy.jbsa.medical-coe.list.dei-center@mail.mil'
              >
                usarmy.jbsa.medical-coe.list.dei-center@mail.mil
              </a>
            </p>
          </div>
          <div className='usa-card__footer'>
            <a
              href='https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/pfw-images/dbimages/MEDCoE DEI Center Flyer.pdf'
              className='usa-button'
              target='_blank'
            >
              Map{' '}
              <FaExternalLinkAlt
                css={css`
                  font-size: small;
                `}
              />
            </a>
          </div>
        </div>
      </li>
    </ul>
  );
};

export default function commandDiversityOffice() {
  return (
    <Layout>
      <SEO title='MEDCoE Diversity, Equity, and Inclusion (DEI) Center' />
      <PageHeader>
        <h1 className='page-header-title'>
          MEDCoE Diversity, Equity, and Inclusion (DEI) Center
        </h1>
      </PageHeader>
      <div className='grid-container'>
        <Mission />
        <Hotlines />
        <CardGrid />
        <Leadership />
      </div>
    </Layout>
  );
}
